import React, { useState } from 'react';
import PortfolioPage from '../../components/PortfolioPage';
import MainImage from '../../images/portfolio/AlienPlanets/episode_2.jpg';
import Progress_1 from '../../images/portfolio/AlienPlanets/CharacterSheetsProff.jpg';
import Progress_2 from '../../images/portfolio/AlienPlanets/InnsideAvSkipet.jpg';
import Progress_3 from '../../images/portfolio/AlienPlanets/StoryBoard.jpg';
const PortfolioAlienPlanets2 = () => {
    return <PortfolioPage img={MainImage} url="https://player.vimeo.com/video/558731943" title="Alien Planets" description={
      <p>Dette bachelor-prosjektet til Magne og Peter, Alien Planets. Alien Planets er tenkt som en animert parodi av naturprogrammer slik de gjerne presenteres på kanalen Animal Planet, og også særlig Netflix serien Our Planet. Vår handling skjer på ulike planeter i verdensrommet. 
        <br />
        <br />
      Vi følger Professor Stephen Von Monocle (som spesialiserer seg i utenomjordiske arter) og hans kameracrew. Oppdraget deres går ut på å reise til forskjellige planeter for å fange de ulike økosystemene på film mens professoren forklarer hva som foregår. Professoren vet ikke alltid hva han snakker om, og kommer stadig med feil informasjon om skapningene. Dette fører ofte til at de ender opp i humoristiske og utfordrende situasjoner når romvesenene gjør noe fullstendig uforventet og sjokkerende, og er i noen tilfeller aggressive. De har heldigvis en livvakt med seg som, selv om han er litt overmodig, alltid tar på seg angrepene fra de ulike romvesener slik at de andre kan komme seg unna. Alle disse rotete situasjonene og kaoset skaper en forstyrrende og komisk atmosfære som går gjennom hele serien.
      <br />
      <br />
      Vi lagde to pilotepisoder på ca 5 minutter hver. Serien er laget i digital 2D teknikk, og sjangeren er en kombinasjon av satirisk-, parodisk-, slapstick- og sjokk-komedie. Prosjektet vårt er blant annet inspirert av mer “voksne” tegnefilmserier med science-fiction elementer som Rick and Morty, og Futurama. Hovedmålgruppen blir altså unge voksne på vår alder (20 årene) som ønsker å se noe kort og morsomt for å bryte opp hverdagen f.eks. etter skole/jobb. 
      <br />
      <br />
      Hovedmålet vårt med dette bachelorprosjektet var å lære oss hvordan vi mest effektivt kan lage slike episoder til en komisk animasjonsserie fra start til slutt. Vi har tenkt mye på hvilke grep vi kan foreta oss i den tekniske utførelsen av animasjonen, og også i den narrative strukturen, som vil tillate oss å produsere kvantitativt uten at det svekker kvaliteten. Vi er klar over at når vi trer inn i en industri hvor man forventes å skulle levere relativt store mengder animasjon innenfor bestemt tidsrammer, så burde vi lære oss gode teknikker og rutiner for hvordan fullføre dette på best mulig måte. Som del av denne utarbeidingen av effektive arbeidsmetoder, fokuserte vi særlig mye på hvordan vi burde gå fram med den aller første delen av produksjonen (manuset, karakter-designet og storyboardet). Slikt fokus på grundig forarbeid hjalp oss en god del i praksis. Etter hvert da vi kom til de senere deler av produksjonen (animatic, animasjon og composition), ble det underliggende fokuset å få til tilstrekkelig enkel utseende og struktur, samtidig som det skulle bli en kompetent og underholdende animasjonsserie som kan bli vist på flest mulig plattformer eller strømmetjenester.</p>
    }>
         <div className="flex  mt-32 lg:px-0">
            <div className="max-w-sm">
              <img src={Progress_3} />
              
            </div>
          </div>

          <div className="flex flex-wrap  mt-32 justify-between">
          <img src={Progress_2} style={{maxHeight: "300px"}} />
          <img src={Progress_1}  style={{maxHeight: "300px"}}  />
          </div>

    </PortfolioPage>

}

export default PortfolioAlienPlanets2;